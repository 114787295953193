import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InitialsPipe } from '../../pipes/initials.pipe';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-profile-picture',
  standalone: true,
  imports: [
    // 3rd
    NgClass,
    // Project
    InitialsPipe,
  ],
  templateUrl: './profile-picture.component.html',
  styleUrl: './profile-picture.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePictureComponent {
  @Input({ required: true }) public firstName!: string;
  @Input({ required: true }) public lastName!: string;
  @Input({ required: true }) public width!: string;
  @Input({ required: true }) public height!: string;
}
